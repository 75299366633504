import { useState } from "react"
import { NavLink, useNavigate } from "react-router"
import fetchData from "../../../helper/fetchData"


function ProjectAdd() {
    const [projectName, setProjectName] = useState<string>()
    const [projectDescription, setProjectDescription] = useState<string>()
    const [projectURL, setProjectURL] = useState<string>()

    const navigate = useNavigate()

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            projectName: projectName,
            projectDescription: projectDescription,
            projectURL: projectURL
        }

        const response = await fetchData('projects/create', 'post', JSON.stringify(data))
        if(response.responseCode === 200){
            navigate('/projects')
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            Add Project
                        </div>
                    </div>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="user_name">Project name:</label>
                                        <input
                                            type="text"
                                            name="project_name"
                                            className="form-control"
                                            required
                                            autoComplete="off"
                                            onChange={(e) => setProjectName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Description:</label>
                                        <textarea
                                            name="project_description"
                                            className="form-control"
                                            onChange={(e) => setProjectDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="user_name">Project URL (production):</label>
                                        <input
                                            type="url"
                                            name="project_url"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => setProjectURL(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-action text-center">
                            <button className="btn btn-success" type="submit">Submit</button>
                            <NavLink to={'/setting/projects'} className="btn btn-danger ms-1">Cancel</NavLink>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProjectAdd