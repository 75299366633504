import { useState } from "react";
import { NavLink, useNavigate } from "react-router";
import fetchData from "../../../helper/fetchData";
import Breadcrumb from "../layout/Breadcrumb";

export default function UserAdd() {

    const [userName, setUserName] = useState<String>()
    const [email, setEmail] = useState<String>()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true)
            e.preventDefault()
            const data = {
                userName: userName,
                email: email,
            }

            const response = await fetchData('users/create', 'post', JSON.stringify(data))
            if (response.responseCode === 200) {
                navigate('/setting/users')
            }

        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                    <div className="page-header">
                        <h3 className="fw-bold mb-3">Users</h3>
                        <Breadcrumb
                            props={[{
                                caption: 'Users',
                                link: '/setting/users'
                            },
                            {
                                caption:'Add',
                                link:'/setting/users/add'
                            }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Add User
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="form-group">
                                            <label htmlFor="user_name">Name of the user:</label>
                                            <input type="text" className="form-control" required autoComplete="off" onChange={(e) => setUserName(e.target.value)} id="user_name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email of the user:</label>
                                            <input type="email" className="form-control" required autoComplete="off" onChange={(e) => setEmail(e.target.value)} id="email" />
                                            <small id="emailHelp2" className="form-text text-muted">
                                                User must sign in with this email id only
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-action req.bodytext-center">
                                <button className="btn btn-success" disabled={loading} type="submit">Submit</button>
                                <NavLink to={'/setting/users'} className="btn btn-danger ms-1">Cancel</NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}