import { login } from '../oidc-auth/oidcService'
import { MdArrowRightAlt } from "react-icons/md";

export default function LoginScreen() {

    return (
        <div className='wrapper'>
            <div className="main-header" style={{ width: '100%' }}>
                <nav data-background-color="dark2" className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
                    <div className='container-fluid'>
                        <a href="https://stockedge.com" className="navbar-brand">
                            <img
                                src="https://duzycfafl38re.cloudfront.net/Website/Images/stockedgelogoimage05122023142706.webp"
                                alt="navbar brand"
                                className="d-inline-block align-top"
                                width={50}
                            />
                        </a>
                        <span className="ms-2 text-light lead">
                            ApiDoc
                        </span>
                        <div className='justify-content-end navbar-collapse collapse'>
                            <button className='btn btn-primary ps-5 pe-5' onClick={login}>Login</button>
                        </div>
                    </div>
                </nav>
            </div>
            <div className='container' style={{ paddingTop: '10vh' }}>
                <div className='page-inner'>
                    <div className='row'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'>
                                    Welcome to the Central API Documentation Project
                                </div>
                            </div>
                            <div className='card-body'>
                                <p>
                                    The Central API Documentation Project aims to provide developers with a comprehensive and unified resource
                                    for all our API services. This project consolidates various documentation sources to enhance usability
                                    and streamline access to API information.
                                </p>
                                <p className='lead mt-3'>
                                    Features
                                </p>
                                <div className='ps-3 pt-2'>
                                    <ol style={{ listStyleType: 'revert' }}>
                                        <li className='p-1'>
                                            Unified API Reference
                                        </li>
                                        <li className='p-1'>Interactive Examples</li>
                                        <li className='p-1'>Up-to-date Tutorials</li>
                                    </ol>
                                </div>
                                <p className='lead mt-3'>
                                    To start using the Central API, follow these simple steps:
                                </p>
                                <div className='ps-3 pt-2'>
                                    <ol style={{ listStyleType: 'revert' }}>
                                        <li className='p-1'>
                                            Create Account in Stockedge.com portal
                                        </li>
                                        <li className='p-1'>Ask your manager to add access to your project / module</li>
                                        <li className='p-1'>Start browsing API Docs</li>
                                    </ol>
                                </div>
                                <p className='lead mt-3'>
                                    For any queries / suggestions:
                                    Please drop an email to: sanjib@stockedge.com
                                </p>
                                <div className='mt-2' >
                                    <button className='btn btn-primary ps-5 pe-5' style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} onClick={login}>
                                        Start Browing API Doc <MdArrowRightAlt className='ms-2' size={20} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer" style={{ bottom: 0 }}>
                <div className="container-fluid d-flex justify-content-between">
                    <nav className="pull-left">
                        <ul className="nav">
                            <li className="nav-item">
                                <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/privacypolicy"> Privacy Policy  </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/terms"> Terms of use </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/disclaimer"> Disclaimer </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="copyright">
                        &copy;{new Date().getFullYear()}, Kredent InfoEdge Pvt. Ltd
                    </div>
                </div>
            </footer>
        </div>
    )
}