import { useEffect, useState } from "react"
import { useParams } from "react-router"
import fetchData from "../../../../helper/fetchData"
import { FaTrash } from "react-icons/fa6";
import Breadcrumb from "../../layout/Breadcrumb";
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async'


export default function ModuleAccess() {

    const { mid } = useParams()

    useEffect(() => {
        getModulePermissionList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mid])

    const [loading, setLoading] = useState(true)
    const [modulePermission, setModulesionPermission] = useState<ProjectPermissionType[]>([])
    const [projectName, setProjectName] = useState<string>()
    const [moduleInfo, setModuleInfo] = useState<ModuleType>()


    const [userItem, setUserItem] = useState<UserType | null>(null)

    const getModulePermissionList = async () => {

        try {
            setLoading(true)
            const response = await fetchData(`modules/permission/${mid}`, "get", "")
            setModulesionPermission(response.response.permissions)
            setProjectName(response.response.project.name)
            setModuleInfo({ id: response.response.id, name: response.response.name, projectId: response.response.projectId, apiUrl: "", project: { name: "" } })
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)


    const getUserByEmail = async (inputValue: string) => {
        try {
            if (inputValue.length <= 8) {
                return false
            }
            const response = await fetchData('users/suggestion-user', 'post', JSON.stringify({ email: inputValue }))
            return response.response
        } catch (error) {
            return null
        }
    }

    const [permissionAddLoading, setPermissionAddLoading] = useState(false)

    const addModulePermission = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (userItem !== null) {
                setPermissionAddLoading(true)
                if (moduleInfo !== undefined) {

                    const response = await fetchData('modules/add-permission', "post", JSON.stringify({
                        projectId: moduleInfo.projectId,
                        moduleId: mid,
                        userId: userItem.id
                    }))

                    if (response.responseCode === 200) {
                        setModulesionPermission([...modulePermission, { user: userItem, id: response.response.id }])
                    }
                }
            }

        } catch (error) {

        } finally {
            setPermissionAddLoading(false)
        }
    }

    const deleteProjectPermission = async (id: number, index: number) => {
        try {
            alert(id)
            alert(index)
            const response = await fetchData(`modules/delete-permission/${id}`, "get", "")
            if (response.responseCode === 200) {
                let permissionArr = [...modulePermission]
                setModulesionPermission(permissionArr.splice((index + 1), 1))
            }
        } catch (error) {
            return null
        }
    }

    return (
        <>
            {
                loading === false && <>

                    <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                        <div>
                            <div className="page-header">
                                <h3 className="fw-bold mb-3">{projectName} Permissions</h3>
                                <Breadcrumb
                                    props={[
                                        {
                                            caption: 'Project',
                                            link: `/setting/projects`
                                        },
                                        {
                                            caption: 'Access Control',
                                            link: `/setting/projects/access/${mid}`
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="ms-md-auto py-2 py-md-0">
                            <button className="btn btn-primary btn-round" onClick={handleShow}>Add Permission</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">User List of - {moduleInfo?.name} under {projectName}</div>
                                </div>
                                <div className="card-body">
                                    <table className="table table-hover table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading === false && modulePermission.length > 0 && modulePermission.map((permission, i) => (
                                                    <tr key={i}>
                                                        <td>{permission.user.name}</td>
                                                        <td>{permission.user.email}</td>
                                                        <td>
                                                            <button title="Delete permission" onClick={() => deleteProjectPermission(permission.id, i)}>
                                                                <FaTrash size={20} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                loading === false && modulePermission.length <= 0 &&
                                                <tr>
                                                    <td colSpan={3}>No records found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <form onSubmit={addModulePermission}>

                            <Modal.Header closeButton>
                                <Modal.Title>Add View access - {moduleInfo?.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AsyncSelect
                                    loadOptions={getUserByEmail}
                                    getOptionLabel={(item: UserType) => item.email}
                                    getOptionValue={(item: UserType) => item.name}
                                    defaultOptions={[]}
                                    placeholder="Type Email Id"
                                    onChange={(item) => setUserItem(item)}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-success" type="submit" disabled={permissionAddLoading}>Submit</button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </>
            }
        </>
    )
}