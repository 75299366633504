import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router";
import fetchData from "../../../../helper/fetchData";
import { SiGoogledocs } from "react-icons/si";
import Breadcrumb from "../../layout/Breadcrumb";
import { FaShieldVirus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";

export default function ModuleList() {
    let { pid } = useParams()
    useEffect(() => {
        getProjectList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [moduleList, setModuleList] = useState<ModuleType[]>()
    const [loading, setLoading] = useState(true)
    const [projectInfo, setProjectInfo] = useState<ProjectsType>()

    const getProjectList = async () => {

        try {
            setLoading(true)
            const response = await fetchData(`modules/list/${pid}`, "get", "")
            if (response.responseCode === 200) {
                setModuleList(response.response.module)
                setProjectInfo(response.response)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            {
                loading === false && <>
                    <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                        <div className="page-header">
                            {
                                projectInfo !== undefined && <>
                                    <h3 className="fw-bold mb-3">Modules of - {projectInfo.name}</h3>
                                    <Breadcrumb
                                        props={[
                                            {
                                                caption: 'Projects',
                                                link: '/setting/projects'
                                            },
                                            {
                                                caption: 'Modules',
                                                link: `/projects/${projectInfo.id}/module/list`
                                            }
                                        ]}
                                    />
                                </>
                            }
                        </div>
                        <div className="ms-md-auto py-2 py-md-0">
                            <NavLink to={`/projects/${pid}/module/add`} className="btn btn-primary btn-round">Add Module</NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">Module List</div>
                                </div>
                                <div className="card-body">
                                    <table className="table table-hover table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading === false && moduleList !== undefined && moduleList.length > 0 && moduleList.map((moduleItem, i) => (
                                                    <tr key={i}>
                                                        <td>{moduleItem.name}</td>
                                                        <td>{moduleItem.description}</td>
                                                        <td>
                                                            <div className="row">
                                                                <div style={{ display: 'inline-flex' }}>
                                                                    <NavLink to={`/moduledoc/${moduleItem.id}`} title="Api Docs" className="me-2">
                                                                        <SiGoogledocs size={20} />
                                                                    </NavLink>
                                                                    <NavLink title="Edit" to={`/modules/edit/${moduleItem.id}`} className="me-2">
                                                                        <FaEdit size={20} />
                                                                    </NavLink>
                                                                    <NavLink title="Access control" to={`/modules/access/${moduleItem.id}`}>
                                                                        <FaShieldVirus size={20} />
                                                                    </NavLink>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                loading === false && moduleList !== undefined && moduleList.length <= 0 &&
                                                <tr>
                                                    <td colSpan={3}>
                                                        No Data found
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}