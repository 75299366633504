import { useEffect, useState } from "react"
import { NavLink } from "react-router"
import fetchData from "../../../helper/fetchData"
import { FaShieldVirus } from "react-icons/fa6";
import { RiSoundModuleFill } from "react-icons/ri";
import Breadcrumb from "../layout/Breadcrumb";



function ProjectList() {
    useEffect(() => {
        getProjectList()
    }, [])

    const [projectList, setProjectList] = useState<ProjectsType[]>([])
    const [loading, setLoading] = useState(true)

    const getProjectList = async () => {

        try {
            setLoading(true)
            const response = await fetchData('projects/list', "get", "")
            setProjectList(response.response.projectList)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                    <div className="page-header">
                        <h3 className="fw-bold mb-3">Projects</h3>
                        <Breadcrumb
                            props={[
                                {
                                    caption: 'Projects',
                                    link: '/setting/projects'
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="ms-md-auto py-2 py-md-0">
                    <NavLink to={'/setting/projects/add'} className="btn btn-primary btn-round">Add Project</NavLink>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Project List</div>
                        </div>
                        <div className="card-body">
                            <table className="table table-hover table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">URL</th>
                                        <th scope="col">Added On</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading === false && projectList.length > 0 && projectList.map((project, i) => (
                                            <tr key={i}>
                                                <td>{project.name}</td>
                                                <td>{project.url}</td>
                                                <td>{new Date(project.createdOn).toDateString()}</td>
                                                <td>
                                                    <div className="row">
                                                        <div style={{ display: 'inline-flex' }} >
                                                            <NavLink to={`/projects/${project.id}/module/list`} title="Modules" className="me-2">
                                                                <RiSoundModuleFill size={20} />
                                                            </NavLink>
                                                            <NavLink title="Access control" to={`/setting/projects/access/${project.id}`}>
                                                                <FaShieldVirus size={20} />
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectList