import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import fetchData from '../../helper/fetchData';
import '../../assets/css/customStyle.css'

const ModuleDoc = () => {
    let { mid } = useParams()
    useEffect(() => {
        getModuleInfo()
    }, [])

    const [moduleInfo, setModuleInfo] = useState<ModuleType>()
    const [loading, setLoading] = useState(true)

    const getModuleInfo = async () => {

        try {
            setLoading(true)
            const response = await fetchData(`modules/getinfo/${mid}`, "get", "")
            setModuleInfo(response.response)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="custom-sidebar">
            {
                loading === false && moduleInfo !== undefined &&
                <API
                    router='hash'
                    layout='sidebar'
                    apiDescriptionUrl={moduleInfo.apiUrl} />
            }
        </div>
    )
}


export default ModuleDoc