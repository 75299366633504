import { useEffect } from 'react';
import { completeLogin } from '../oidc-auth/oidcService'

export default function LoginSuccess() {
    useEffect(() => {
        completeLogin().then(() => window.location.href = '/')
        .catch(err => {
            alert(err)
        });
}, []);

return (
    <div>
        Please wait....
    </div>
)
}
