import { Outlet, useLocation } from "react-router"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/kaiadmin.min.css'
import Sidebar from "./Sidebar";
import Header from "./Header";
import { getToken } from "../../oidc-auth/oidcService";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import fetchData from "../../../helper/fetchData";

function Layout() {

    const [isSidebarVisible, setIsSidebarVisible] = useState(true)

    let location = useLocation()

    useEffect(() => {
        checkLogin()

        if (location.pathname.match('/moduledoc')) {
            setIsSidebarVisible(false)
            setclassSideBar('sidebar_minimize')
        } else {
            setclassSideBar('')
        }
    }, [location])

    const tokenValue = getToken()
    if (tokenValue === null || tokenValue === undefined) {
        window.location.href = '/login'
    }

    const [classSideBar, setclassSideBar] = useState('')

    const onMouseIn = () => {
        if (isSidebarVisible === false) {
            setclassSideBar('sidebar_minimize sidebar_minimize_hover')
        }
    }

    const onMouseOut = () => {
        if (isSidebarVisible === false) {
            setclassSideBar('sidebar_minimize')
        }
    }


    const checkLogin = async()=>{
        try {
            const response = await fetchData('login/is-logged-in',"get","")
            if(response.responseCode === 200){
                const r = response.response.success as boolean
                if(r === false){
                    window.location.href = '/login'
                }
            }
        } catch (error) {
            window.location.href = '/login'
        }   
    }

    return (
        <div className={`wrapper ${classSideBar} `} >
            <Sidebar onMouseIn={onMouseIn} onMouseOut={onMouseOut} />
            <div className="main-panel">
                <Header />
                <div className="container">
                    <div className="page-inner">
                        <Outlet />
                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                    </div>
                </div>
                <footer className="footer" style={{ bottom: 0,position:'fixed' }}>
                    <div className="container-fluid d-flex justify-content-between">
                        <nav className="pull-left">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/privacypolicy"> Privacy Policy  </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/terms"> Terms of use </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" target='_blank' rel="noreferrer" href="https://stockedge.com/disclaimer"> Disclaimer </a>
                                </li>
                            </ul>
                        </nav>
                        <div className="copyright">
                            &copy;{new Date().getFullYear()}, Kredent InfoEdge Pvt. Ltd
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Layout