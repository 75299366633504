import { NavLink } from "react-router";
import { FaHome } from "react-icons/fa";
import React from "react";
export default function Breadcrumb({props}: {props:BreadcrumbType[]}) {
    return (
        <ul className="breadcrumbs mb-3">
            <li className="nav-home">
                <NavLink to={'/'}>
                    <FaHome />
                </NavLink>
            </li>
            <li className="separator">
            </li>
            <li className="nav-item">
                <NavLink to={'/'}>Home</NavLink>
            </li>

            {
                props.map((bItem, i) => (
                    <React.Fragment key={i}>
                        <li className="separator">
                            {`>`}
                        </li>
                        <li className="nav-item">
                            <NavLink to={bItem.link}>{bItem.caption}</NavLink>
                        </li>
                    </React.Fragment>
                ))
            }
        </ul>
    )
}