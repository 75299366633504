import { Routes, Route } from "react-router";
import App from "../App";
import LoginScreen from "../components/screens/LoginScreen";
import Layout from "../components/screens/layout/Layout";
import LoginSuccess from "../components/screens/LoginSuccess";
import Documentation from "../components/screens/ModuleDoc";
import UsersList from "../components/screens/users/UsersList";
import UserAdd from "../components/screens/users/UserAdd";
import ProjectAdd from "../components/screens/projects/ProjectAdd";
import ProjectList from "../components/screens/projects/ProjectList";
import ProjectAccess from "../components/screens/projects/ProjectAccess";
import ModuleAdd from "../components/screens/projects/modules/ModuleAdd";
import ModuleList from "../components/screens/projects/modules/ModuleList";
import ModuleAccess from "../components/screens/projects/modules/ModuleAccess";

export default function RouteMain() {
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path='/login-success' element={<LoginSuccess />} />
            <Route element={<Layout />}>
                <Route index path="/" element={<App />} />
                <Route path="/setting/users" element={<UsersList />} />
                <Route path="/setting/users/add" element={<UserAdd />} />
                <Route path="/setting/projects/add" element={<ProjectAdd />} />
                <Route path="/setting/projects" element={<ProjectList />} />
                <Route path="/setting/projects/access/:pid" element={<ProjectAccess />} />
                <Route path="/projects/:pid/module/add" element={<ModuleAdd />} />
                <Route path="/projects/:pid/module/list" element={<ModuleList />} />
                <Route path="/moduledoc/:mid" element={<Documentation />} />
                <Route path="/modules/access/:mid" element={<ModuleAccess />} />
                <Route path="/modules/edit/:mid" element={<ModuleAdd />} />
            </Route>
        </Routes>
    )
}