// import './style/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router';
import RouteMain from './route';
// // import {  AuthProviderProps } from 'react-oidc-context';
// import { AuthProviderProps } from './context/AuthProvider';
// import App from './App';
//  import { AuthProvider } from './context/AuthProvider';
// //import { AuthProvider } from 'react-oidc-context';



// const oAuthDomain = process.env.REACT_APP_OAUTH_DOMAIN as string

// const oidcConfig: AuthProviderProps = {
//   authority: oAuthDomain,
// 	client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
// 	redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/login-success`,
//   scope: process.env.REACT_APP_ALLOWED_SCOPES as string,
// };

// ReactDOM.render(
//   <React.StrictMode>
//     		<AuthProvider {...oidcConfig}>
//           {/* <CustomAuthProvider> */}
//              <App />
//           {/* </CustomAuthProvider> */}
// 	      </AuthProvider>

//   </React.StrictMode>,
//   document.getElementById('root'),
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);  
root.render(
    <BrowserRouter>
      <RouteMain />
    </BrowserRouter>
);


