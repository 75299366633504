import { UserManager } from 'oidc-client-ts';
import { oidcConfig } from './oidcConfig';
import fetchData from '../../helper/fetchData';

const userManager = new UserManager(oidcConfig);

export const login = () => userManager.signinRedirect();
export const logout = () => userManager.signoutRedirect();
export const getUser = () => userManager.getUser();
export const completeLogin = async () => await userManager.signinRedirectCallback().then(async (user) => {

    const response = await fetchData(
        'login/login-success-check',
        'post',
        JSON.stringify({
            accessToken: user.access_token,
        }),
        user.id_token as string,
    )
    if (response.responseCode === 200) {
        setToken(response.response.name, response.response.picture)
    }
});
export const completeLogout = () => userManager.signoutRedirectCallback().then(async () => {
    const response = await fetchData('login/logout', "get", "")
    if (response.responseCode === 200) {
        clearToken()
        window.location.href = '/login'
    }
});

const setToken = (name: string, picture: string) => {
    localStorage.setItem('id_token', new Date().toString());
    localStorage.setItem("name", name)
    localStorage.setItem("pic", picture)
};

export const getToken = () => {
    return localStorage.getItem('id_token');
};

const clearToken = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem("name")
    localStorage.removeItem("pic")
};