/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink } from "react-router";
import Breadcrumb from "../layout/Breadcrumb";
import { useState } from "react";
import fetchData from "../../../helper/fetchData";
import { FaTrash } from "react-icons/fa6";
import { toast } from "react-toastify";

export default function UsersList() {

    const [email, setEmail] = useState<String>()
    const [loading, setLoading] = useState(false)
    const [userList, setUserList] = useState<UserType[]>([])

    const search = async () => {
        try {
            if (email !== undefined && email.length <= 5) {
                return false
            }
            setLoading(true)
            const response = await fetchData('users/suggestion-user', 'post', JSON.stringify({ email: email }))
            if (response.responseCode === 200) {
                setUserList(response.response)
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const deleteUser = async (userId: number) => {
        try {
            const response = await fetchData(`users/delete/${userId}`, 'get', "")
            if (response.responseCode === 200) {
                toast.success("User Deleted")
                setUserList([])
            }
        } catch (error) {

        }
    }

    return (
        <>
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                    <div className="page-header">
                        <h3 className="fw-bold mb-3">Users</h3>
                        <Breadcrumb
                            props={[{
                                caption: 'Users',
                                link: '/setting/users'
                            }
                            ]}
                        />
                    </div>
                </div>
                <div className="ms-md-auto py-2 py-md-0">
                    <NavLink to={'/setting/users/add'} className="btn btn-primary btn-round">Add User</NavLink>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-1">
                                    <div className="card-title">List</div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button type="submit" className="btn btn-search pe-1">
                                                <i className="fa fa-search search-icon"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Search by email..."
                                            className="form-control"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-1">
                                    <button className="btn btn-secondary btn-round"
                                        disabled={loading}
                                        onClick={search}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading === false && userList.length >= 0 && userList.map((userItem, i) => (

                                            <tr key={i}>
                                                <td>{userItem.name}</td>
                                                <td>{userItem.email}</td>
                                                <td>
                                                    <a title="delete user"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => deleteUser(userItem.id)}>
                                                        <FaTrash />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        loading === false && userList.length <= 0 &&
                                        <tr>
                                            <td colSpan={3}>No records found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}