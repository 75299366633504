
import { toast } from "react-toastify";

const fetchData = async (url: string, method: string = 'get', data: string | FormData, token: string | null = null, headers_type: null | string = null) => {

    let response = null, responseCode = null

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
    }
    let options: any

    

    if (method.toLowerCase() === 'get') {
        options = {
            method: 'GET',
            headers,
        }
    } else if (method.toLowerCase() === 'post') {
        options = {
            method: 'POST',
            headers,
            body: data,

        }
    } else if (method.toLowerCase() === 'delete') {
        options = {
            method: 'DELETE',
            headers
        }
    }

    if (headers_type === 'multi') {
        options = {
            method: 'POST',
            body: data
        }
    }

    options = { ...options, credentials: 'include' }
    try {
        response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, options).then((res) => {
            responseCode = res.status
            if (res.status === 401) {
                toast.error("Access denied")
                throw new Error("401")
            }
            else if (res.status === 403) {
                
            }
            else if (res.status === 422) {
                return res.json()
            }
            else if (res.status === 200) {
                return res.json()
            }
            else if (res.status === 500) {
                toast.error("Some error occurred or access denied")
                throw new Error("403")
            }
        })

    } catch (error) {
        response = null
    } finally {
        return {
            response: response,
            responseCode: responseCode
        }
    }
}

export default fetchData
