/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { FaCogs, FaHome } from "react-icons/fa"
import { NavLink } from "react-router"

const MENU =
    [
        {
            caption: 'Settings',
            navLink: '/setting',
            child: [
                {
                    caption: 'Users',
                    navLink: '/users'
                },
                {
                    caption: 'Projects',
                    navLink: '/projects'
                },
            ]
        }

    ]


export default function Sidebar({ onMouseIn, onMouseOut }: { onMouseIn: () => void, onMouseOut: () => void }) {

    const activeSubmenu = (isMatch: string) => {
        const pathname = window.location.pathname

        if(isMatch === "/" && pathname.length !== 1) return false
        
        if (pathname.match(isMatch)) {
            return true
        }
    }
    const [openSettingMenu, setOpenSettingMenu] = useState(false)

    return (
        <div className="sidebar" data-background-color="dark2" onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}>
            <div className="sidebar-logo">
                <div className="logo-header" data-background-color="dark2">
                    <a href="/" className="logo">
                        <img
                            src="https://duzycfafl38re.cloudfront.net/Website/Images/stockedgelogoimage05122023142706.webp"
                            alt="navbar brand"
                            className="navbar-brand"
                            style={{ height: '50px' }}
                        />
                        <h3 className="ms-2 text-light">
                            ApiDoc
                        </h3>
                    </a>
                    <div className="nav-toggle">
                        <button className="btn btn-toggle toggle-sidebar" onClick={onMouseIn}>
                            <i className="gg-menu-right"></i>
                        </button>
                        <button className="btn btn-toggle sidenav-toggler">
                            <i className="gg-menu-left"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="sidebar-wrapper scrollbar scrollbar-inner" onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}>
                <div className="sidebar-content">
                    <ul className="nav nav-secondary">
                        <li className={`nav-item ${activeSubmenu("/") ? 'active' : ''}`}>
                            <NavLink
                                to="/"
                                className={`collapsed`}
                            >
                                <i><FaHome /></i>
                                <p>Dashboard</p>
                            </NavLink>
                        </li>
                        {
                            MENU.map((menuItem, i) => (
                                <li className={`nav-item  ${activeSubmenu("setting") ? 'active submenu' : ''}`} key={i}>
                                    <a
                                        onClick={() => setOpenSettingMenu(!openSettingMenu)}
                                        className="collapsed"
                                        style={{cursor:'pointer'}}
                                    >
                                        <i><FaCogs /></i>
                                        <p>{menuItem.caption}</p>
                                        <span className="caret"></span>
                                    </a>
                                    <div className={`collapse ${activeSubmenu("setting") || openSettingMenu === true ? 'show' : ''}`}>
                                        <ul className="nav nav-collapse">
                                            {
                                                menuItem.child.map((childItem, j) => (

                                                    <li key={j} className={`${activeSubmenu(childItem.navLink) ? 'active' : ''}`}>
                                                        <NavLink to={`${menuItem.navLink}${childItem.navLink}`}>
                                                            <span className="sub-item">{childItem.caption}</span>
                                                        </NavLink>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

        </div>
    )
}
