import { NavLink, useNavigate, useParams } from "react-router";
import Modal from 'react-bootstrap/Modal'
import { useEffect, useState } from "react";
import fetchData from "../../../../helper/fetchData";

export default function ModuleAdd() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)

    const { pid, mid } = useParams()

    let navigate = useNavigate()
    const [projectId, setProjectId] = useState(pid)
    const saveModule = async (e: React.FormEvent<HTMLFormElement>) => {
        try {

            e.preventDefault()
            const formData = new FormData(e.currentTarget)
            const response = await fetchData('modules/create', "post", formData, null, 'multi')
            if (response.responseCode === 200) {
                navigate(`/projects/${projectId}/module/list`)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchModule()
    }, [mid])

    const [moduleName, setModuleName] = useState<string>("")
    const [moduleDescription, setModuleDescription] = useState<string>("")
    const [apiUrl, setApiUrl] = useState<string>("")
    
    const fetchModule = async () => {
        try {
            if (mid !== undefined) {
                const response = await fetchData(`modules/getinfo/${mid}`, "get", "")
                if (response.responseCode === 200) {
                    setModuleName(response.response.name)
                    setModuleDescription(response.response.description)
                    setApiUrl(response.response.apiUrl)
                    setProjectId(response.response.projectId)
                }
            }
        } catch (error) {

        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            Add Module
                        </div>
                    </div>
                    <form onSubmit={(e) => saveModule(e)}>
                        <input type="hidden" readOnly value={mid} name="moduleId" />
                        <input type="hidden" readOnly value={projectId} name="projectId" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label htmlFor="moduleName">Module name:</label>
                                        <input
                                            type="text"
                                            name="moduleName"
                                            className="form-control"
                                            required
                                            id="moduleName"
                                            autoComplete="off"
                                            defaultValue={moduleName}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="moduleDescription">Description:</label>
                                        <textarea
                                            name="moduleDescription"
                                            id="moduleDescription"
                                            className="form-control"
                                            defaultValue={moduleDescription}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="file">API URL:</label>
                                        <input type="file" name="file" className="form-control" autoComplete="off" />
                                        <small className="form-text text-muted">{apiUrl}</small><br />
                                        <small className="form-text text-danger"
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleShow}
                                        >
                                            How to save postman collection
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-action text-center">
                            <button className="btn btn-success" type="submit">Submit</button>
                            <NavLink to={`/projects/${projectId}/module/list`} className="btn btn-danger ms-1">Cancel</NavLink>
                        </div>
                    </form>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>How to save postman collection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Step 1:</strong> Open Postman on your computer.</p>
                    <p><strong>Step 2:</strong> In the left sidebar, click on the collection you want to export.</p>
                    <p><strong>Step 3:</strong> Next to the collection name, click on the three dots to open a dropdown menu.</p>
                    <p><strong>Step 4:</strong> In the dropdown menu, select the 'Export' option.</p>
                    <p><strong>Step 5:</strong> Choose 'Collection v2.1 (recommended)' as the version.</p>
                    <p><strong>Step 6:</strong> Click 'Export' and choose a location on your computer to save the file.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}