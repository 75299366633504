import { useEffect, useState } from "react";
import Breadcrumb from "./components/screens/layout/Breadcrumb";
import fetchData from "./helper/fetchData";
import { NavLink } from "react-router";
import { MdArrowRightAlt } from "react-icons/md";

export default function App() {
    useEffect(() => {
        getDashbordResults()
    }, [])

    const [dashboard, setDashboard] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const getDashbordResults = async () => {
        setLoading(true)
        const response = await fetchData('users/dashboard', "get", "")
        if (response.responseCode === 200) {
            setDashboard(response.response)
        }
        setLoading(false)
    }

    return (
        <>
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
                <div>
                    <div className="page-header">
                        <h3 className="fw-bold mb-3">Dashboard</h3>
                        <Breadcrumb
                            props={[{
                                caption: 'Dashboard',
                                link: '/'
                            }
                            ]}
                        />
                    </div>
                </div>
            </div>
            {
                loading !== true && dashboard !== null && (dashboard.accessLevel === 1 || dashboard.accessLevel === 4) &&

                <div className="row row-card-no-pd">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6><b>Total Users</b></h6>
                                    </div>
                                    <h4 className="text-info fw-bold">{dashboard.userResponse}</h4>
                                </div>
                                <NavLink to={'/setting/users'} className="pull-right mt-3" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    View all <MdArrowRightAlt className='ms-2' size={20} />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6><b>Total Projects</b></h6>
                                    </div>
                                    <h4 className="text-success fw-bold">{dashboard.projectResponse.total}</h4>
                                </div>
                                <NavLink to={'/setting/projects'} className="pull-right mt-3" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    View all <MdArrowRightAlt className='ms-2' size={20} />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6><b>Total Modules</b></h6>
                                    </div>
                                    <h4 className="text-success fw-bold">{dashboard.moduleResponse}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                loading !== true && dashboard !== null && (dashboard.accessLevel === 3) &&
                <>
                    <h3 className="fw-bold mb-3" style={{ fontSize: '1.4rem' }}>Api Documents</h3>
                    <div className="row">
                        {
                            dashboard.moduleResponse.length > 0 && dashboard.moduleResponse.map((item: ModuleType, i: number) => (
                                <div className="col-12 col-sm-6 col-md-6 col-xl-4" key={i}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6><b>{item.name}</b></h6>
                                                    <p className="text-muted">
                                                        {
                                                            (item.description === undefined || item.description.length <= 0) && <>
                                                                <br />
                                                            </>
                                                        }
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-2">
                                                <NavLink to={`/moduledoc/${item.id}`} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                    View Doc <MdArrowRightAlt className='ms-2' size={20} />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }
            {
                loading !== true && dashboard !== null && (dashboard.accessLevel !== 3) &&
                <>
                    <h3 className="fw-bold mb-3" style={{ fontSize: '1.4rem' }}>Projects</h3>
                    <div className="row">
                        {
                            dashboard.projectResponse.projectList.map((item: ProjectsType, i: number) => (
                                <div className="col-12 col-sm-6 col-md-6 col-xl-4" key={i}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6><b>{item.name}</b></h6>
                                                    <p className="text-muted">
                                                        {
                                                            (item.description === null || item.description.length <= 0) && <>
                                                                <br />
                                                            </>
                                                        }
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-2">
                                                <NavLink to={`/projects/${item.id}/module/list`} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                    View Modules <MdArrowRightAlt className='ms-2' size={20} />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }
        </>
    )
}